import React, { useEffect } from "react";
import * as Sentry from "@sentry/node";
import Head from "next/head";
import TranslationsProvider from "lib/translations/TranslationsProvider";
import { loadTranslations } from "lib/translations";

import "./styles.scss";
import { DrawerProvider } from "components/Drawer";

const isProduction = process.env.NODE_ENV === "production";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: isProduction,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

function MyApp({ Component, pageProps }) {
  const [translations, setTranslations] = React.useState(
    pageProps.translations ?? { locale: pageProps.locale, translations: {} }
  );

  useEffect(() => {
    async function onLocaleChange(locale) {
      if (locale !== translations.locale) {
        const loadedTranslations = await loadTranslations(locale);
        setTranslations(loadedTranslations);
      }
    }
    onLocaleChange(pageProps.locale);
  }, [pageProps.locale]);

  return (
    <TranslationsProvider {...translations}>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link href="/favicon.png" rel="shortcut icon" type="image/png" />
        {isProduction && (
          <React.Fragment>
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=UA-177277460-1`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'UA-177277460-1', {
                      page_path: window.location.pathname,
                    });
                  `,
              }}
            />

            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-N4BKQHK');`,
              }}
            />
          </React.Fragment>
        )}
      </Head>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-N4BKQHK"
          height="0"
          width="0"
          style={{ display: "none", visibility: " hidden" }}
        ></iframe>
      </noscript>

      <DrawerProvider>
        <Head>
          <title>
            Ukrainian IT Awards — це щорічна найпрестижніша премія в галузі IT
          </title>
        </Head>
        <Component {...pageProps} />
      </DrawerProvider>
    </TranslationsProvider>
  );
}

export default MyApp;
